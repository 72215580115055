import { GoDash, GoPlus } from "react-icons/go";
import RadioInputs from "../common/RadioInputs";
import "./ProductInfoCard.css";

import { useState } from "react";
import ShowcaseStatus from "../common/ShowcaseStatus";
import Button from "../common/Button";

const ProductInfoCard = () => {
  const [quantityNum, setQuantityNum] = useState(1);

  const data = {
    id: 1,
    title: "Decorative Wooden Globe",
    description:
      "A stunning decorative wooden globe made from Thuya wood, perfect for enhancing any home or office space.",
    category: "home decor",
    price: 45.99,
    discountPercentage: 15,
    rating: 4.8,
    stock: 3,
    tags: ["wood decor", "globe", "handcrafted"],
    weight: 1.5,
    dimensions: {
      width: 12.0,
      height: 16.0,
      depth: 12.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890123",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-globe.jpg",
    images: [
      "/assets/imgs/products/football with support.jpg",
      "/assets/imgs/products/football with support.jpg",
    ],
  };

  const stock = 40;

  const handleQtyTyping = () => {
    if (quantityNum === "") return;
    if (quantityNum > stock || quantityNum < 1) setQuantityNum(20);
  };

  return (
    <div className="product-info-card">
      <span className="product-category">{data.category}</span>
      <h1 className="product-title">{data.title}</h1>
      <p className="product-description">{data.description}</p>

      <p className="product-pricing">
        <div className="price">
          <sup
            style={{
              fontSize: "1.1rem",
              marginTop: "-4px",
              color: "#546bc559",
            }}
          >
            $
          </sup>
          {data.price.toFixed() * quantityNum}
        </div>
        <div className="percentage">
          Save {data.discountPercentage.toFixed()}%
        </div>
        <div className="msg">includes all taxes</div>
      </p>

      <div className="product-variants">
        <label for="size" className="product-variant-label">
          Size(cm):
        </label>
        <RadioInputs
          inputName={"input-1"}
          inputValues={["18*18cm", "22*22cm", "24*24cm"]}
        />
      </div>

      <ShowcaseStatus />

      <div className="product-footer">
        <div className="product-quantity">
          <div
            className="plus"
            onClick={() => {
              if (quantityNum >= stock) return;
              setQuantityNum((qty) => qty + 1);
            }}
          >
            <GoPlus />
          </div>
          <input
            type="number"
            id="quantity"
            className="product-quantity-input"
            value={quantityNum}
            onChange={(e) => setQuantityNum(e.target.value)}
            onKeyUp={handleQtyTyping}
          />
          <div
            className="minus"
            onClick={() => {
              if (quantityNum <= 1) return;
              setQuantityNum((qty) => qty - 1);
            }}
          >
            <GoDash />
          </div>
        </div>
        <div className="product-actions">
          <Button text="Add to Cart" />
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCard;
