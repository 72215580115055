import { wood_decors } from "../../data/data";

const Footer = () => {
  const data = wood_decors;

  console.log(data);

  return (
    <footer>
      <div className="footer-category">
        <div className="container">
          <h2 className="footer-category-title">Quick Links</h2>

          {data.map((el) => (
            <div className="footer-category-box" key={el.id}>
              <h3 className="category-box-title">{el.name} :</h3>

              {el.products.map((el) => (
                <a href="#elel" className="footer-category-link" key={el}>
                  {el}
                </a>
              ))}
            </div>
          ))}

          {/* 
          <div className="footer-category-box">
            <h3 className="category-box-title">footwear :</h3>

            <a href="#el" className="footer-category-link">
              sport
            </a>
            <a href="#el" className="footer-category-link">
              formal
            </a>
            <a href="#el" className="footer-category-link">
              Boots
            </a>
            <a href="#el" className="footer-category-link">
              casual
            </a>
            <a href="#el" className="footer-category-link">
              cowboy shoes
            </a>
            <a href="#el" className="footer-category-link">
              safety shoes
            </a>
            <a href="#el" className="footer-category-link">
              Party wear shoes
            </a>
            <a href="#el" className="footer-category-link">
              Branded
            </a>
            <a href="#el" className="footer-category-link">
              Firstcopy
            </a>
            <a href="#el" className="footer-category-link">
              Long shoes
            </a>
          </div>

          <div className="footer-category-box">
            <h3 className="category-box-title">jewellery :</h3>

            <a href="#el" className="footer-category-link">
              Necklace
            </a>
            <a href="#el" className="footer-category-link">
              Earrings
            </a>
            <a href="#el" className="footer-category-link">
              Couple rings
            </a>
            <a href="#el" className="footer-category-link">
              Pendants
            </a>
            <a href="#el" className="footer-category-link">
              Crystal
            </a>
            <a href="#el" className="footer-category-link">
              Bangles
            </a>
            <a href="#el" className="footer-category-link">
              bracelets
            </a>
            <a href="#el" className="footer-category-link">
              nosepin
            </a>
            <a href="#el" className="footer-category-link">
              chain
            </a>
            <a href="#el" className="footer-category-link">
              Earrings
            </a>
            <a href="#el" className="footer-category-link">
              Couple rings
            </a>
          </div>

          <div className="footer-category-box">
            <h3 className="category-box-title">cosmetics :</h3>

            <a href="#el" className="footer-category-link">
              Shampoo
            </a>
            <a href="#el" className="footer-category-link">
              Bodywash
            </a>
            <a href="#el" className="footer-category-link">
              Facewash
            </a>
            <a href="#el" className="footer-category-link">
              makeup kit
            </a>
            <a href="#el" className="footer-category-link">
              liner
            </a>
            <a href="#el" className="footer-category-link">
              lipstick
            </a>
            <a href="#el" className="footer-category-link">
              prefume
            </a>
            <a href="#el" className="footer-category-link">
              Body soap
            </a>
            <a href="#el" className="footer-category-link">
              scrub
            </a>
            <a href="#el" className="footer-category-link">
              hair gel
            </a>
            <a href="#el" className="footer-category-link">
              hair colors
            </a>
            <a href="#el" className="footer-category-link">
              hair dye
            </a>
            <a href="#el" className="footer-category-link">
              sunscreen
            </a>
            <a href="#el" className="footer-category-link">
              skin loson
            </a>
            <a href="#el" className="footer-category-link">
              liner
            </a>
            <a href="#el" className="footer-category-link">
              lipstick
            </a>
          </div> */}
        </div>
      </div>

      <div className="footer-nav">
        <div className="container">
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Popular Categories</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Boxes
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Games
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Home Decor
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Souvenirs
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Clocks
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Products</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Furniture
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                New products
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Best sales
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Contact us
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Sitemap
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Our Company</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Delivery
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Legal Notice
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Terms and conditions
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                About us
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Secure payment
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Services</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Prices drop
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                New products
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Best sales
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Contact us
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Sitemap
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Contact</h2>
            </li>

            <li className="footer-nav-item flex">
              <div className="icon-box">
                <ion-icon name="location-outline"></ion-icon>
              </div>

              <address className="content">
                419 State 414 Rte Beaver Dams, New York(NY), 14812, USA
              </address>
            </li>

            <li className="footer-nav-item flex">
              <div className="icon-box">
                <ion-icon name="call-outline"></ion-icon>
              </div>

              <a href="tel:+607936-8058" className="footer-nav-link">
                (607) 936-8058
              </a>
            </li>

            <li className="footer-nav-item flex">
              <div className="icon-box">
                <ion-icon name="mail-outline"></ion-icon>
              </div>

              <a href="mailto:example@gmail.com" className="footer-nav-link">
                example@gmail.com
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Follow Us</h2>
            </li>

            <li>
              <ul className="social-link">
                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>

                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>

                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>

                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          {/* <img src="./assets/images/payment.png" alt="payment method" className="payment-img">

        <p className="copyright">
          Copyright &copy; <a href="#el">Anon</a> all rights reserved.
        </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
