import { useState } from "react";
import RadioInputs from "../common/RadioInputs";

const ProductMoreDetails = () => {
  const inputValues = ["Description", "Fits & Sizes", "Reviews", "Shipping"];

  const [activeBlock, setActiveBlock] = useState(inputValues[0]);

  return (
    <div className="swiper">
      <div className="swiper-header">
        <RadioInputs
          type="outline"
          inputName={"input-2"}
          inputValues={inputValues}
          setFn={setActiveBlock}
        />
      </div>
      <div className="swiper-body">
        {inputValues.map((el) => activeBlock === el && <div>{el}</div>)}
      </div>
    </div>
  );
};

export default ProductMoreDetails;
