import "./RadioInputs.css";

const RadioInputs = ({
  setFn = "",
  type = "",
  color = "red",
  inputName,
  inputValues,
}) => {
  return (
    <div className={`radio-inputs ${type}`}>
      {inputValues.map((el, i) => (
        <label
          htmlFor={inputName + i}
          className="radio"
          key={inputName + el}
          onClick={() => setFn && setFn(el)}
        >
          <input id={inputName + i} type="radio" name={inputName} checked />
          <span className="name" style={{ background: "" }}>
            {el}
          </span>
        </label>
      ))}

      {/* <label htmlFor="input-radio-1" className="radio">
        <input id="input-radio-1" type="radio" name="radio-1" checked />
        <span className="name">18*18 cm</span>
      </label>
      <label htmlFor="input-radio-2" className="radio">
        <input id="input-radio-2" type="radio" name="radio-1" checked />
        <span className="name">22*22 cm</span>
      </label>

      <label htmlFor="input-radio-3" className="radio">
        <input id="input-radio-3" type="radio" name="radio-1" checked />
        <span className="name">24*24 cm</span>
      </label> */}
    </div>
  );
};

export default RadioInputs;
