import "./ProductPage.css";
import ImageGallery from "../components/containers/ImageGallery";
import ProductInfoCard from "../components/containers/ProductInfoCard";
import ProductMoreDetails from "../components/containers/ProductMoreDetails";
import NewProducts from "../components/sections/NewProducts";

const ProductPage = () => {
  return (
    <div className="product-page">
      <div className="container product-comp">
        <div className="product-location">
          <span className="product-location-dir">Home</span>
          <span className="product-location-dir">Desk Accessoirs</span>
          <span className="product-location-dir active">Paper Folder</span>
        </div>

        <div className="product-gallary">
          <ImageGallery />
        </div>

        <div className="product-info-card">
          <ProductInfoCard />
        </div>

        <div className="product-other-details">
          <ProductMoreDetails />
          {/* <div className="product-preview-details">here is the data</div> */}
        </div>
        <div className="related-products">
          {/* <h1>Related Products</h1> */}

          <NewProducts />
        </div>
        {/* <div className="product-page">
          <nav className="breadcrumbs"> 
            <a href="/" className="breadcrumbs__link">
              Home
            </a>
            <a href="/shop" className="breadcrumbs__link">
              Shop
            </a>
            <span className="breadcrumbs__current">Product Name</span>
          </nav>


          <section className="product-description">
            <h2 className="product-description__title">Product Details</h2>
            <div className="product-description__content">
              <p>
                Detailed information about the product, materials, features,
                etc.
              </p>
            </div>
          </section>

          <section className="product-reviews">
            <h2 className="product-reviews__title">Customer Reviews</h2>
            <div className="product-reviews__list">
              <div className="product-review">
                <p className="product-review__text">"Great product!"</p>
                <p className="product-review__author">- Customer Name</p>
              </div>
            </div>
          </section>

          <section className="related-products">
            <h2 className="related-products__title">You May Also Like</h2>
            <div className="related-products__list">
              <div className="related-product-card">
                <img
                  src="related1.jpg"
                  alt="Related Product 1"
                  className="related-product-card__image"
                />
                <p className="related-product-card__name">Related Product 1</p>
                <p className="related-product-card__price">$49.99</p>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </div>
  );
};

export default ProductPage;
