import { IoSearch } from "react-icons/io5";

import "./Input.css";

const Input = function () {
  return (
    <div className="input-container">
      <input
        type="search"
        name="search"
        className="input"
        placeholder="Enter your product name..."
      />

      <button className="input-icon">
        <IoSearch />
      </button>
    </div>
  );
};

export default Input;
