import { useState } from "react";
import "./ImageGallery.css";

const ImageGallery = () => {
  const images = ["/assets/imgs/products/chess roba3iya.png"];

  const [activeImg, setActiveImg] = useState(images[0]);

  return (
    <div className="product__image-gallery">
      <div className="product__main-image">
        <img src={activeImg} alt="" />
      </div>
      <div className="product__thumbnail-gallery">
        <div className="product__second-image active">
          <img
            src="/assets/imgs/products/chess roba3iya.png"
            onClick={(e) => setActiveImg(e.target.src)}
            alt=""
          />
        </div>
        <div className="product__second-image">
          <img
            src="/assets/imgs/products/chess roba3iya 2.png"
            alt=""
            onClick={(e) => setActiveImg(e.target.src)}
          />
        </div>
        <div className="product__second-image">
          <img
            src="/assets/imgs/products/classeur.png"
            alt=""
            onClick={(e) => setActiveImg(e.target.src)}
          />
        </div>
        <div className="product__second-image">
          <img
            src="/assets/imgs/products/sendo9a.png"
            alt=""
            onClick={(e) => setActiveImg(e.target.src)}
          />
        </div>

        {/* <img
        src="thumb1.jpg"
        alt="Thumbnail 1"
        className="product__thumbnail"
      />

      <img
        src="thumb2.jpg"
        alt="Thumbnail 2"
        className="product__thumbnail"
      /> */}
      </div>
    </div>
  );
};

export default ImageGallery;
