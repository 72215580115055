import "./ShowcaseStatus.css";

const ShowcaseStatus = () => {
  return (
    <div className="showcase-status">
      <div className="wrapper">
        <p>
          already sold: <b>20</b> 
        </p>

        <p>
          available: <b>40</b>
        </p>
      </div>

      <div className="showcase-status-bar"></div>
    </div>
  );
};

export default ShowcaseStatus;
