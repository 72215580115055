import { BiSolidStar, BiStar } from "react-icons/bi";
import { BsBagPlus } from "react-icons/bs";
import { IoIosHeartEmpty } from "react-icons/io";

const ProductCard = ({ productObj }) => {
  return (
    <div className="showcase">
      <div className="showcase-banner">
        <img
          src={productObj.images[0]}
          alt={productObj.title}
          className="product-img default"
          width="300"
        />
        <img
          src={productObj.images[1]}
          alt={productObj.title}
          className="product-img hover"
          width="300"
        />

        <div className="showcase-actions">
          <button className="btn-action">
            <IoIosHeartEmpty />
          </button>

          <button className="btn-action">
            <BsBagPlus />
          </button>
        </div>
      </div>

      <div className="showcase-content">
        <a href="#el" className="showcase-category">
          {productObj.category}
        </a>

        <h3>
          <a href="#el" className="showcase-title">
            {productObj.title}
          </a>
        </h3>

        <div className="showcase-rating">
          <BiSolidStar />
          <BiSolidStar />
          <BiSolidStar />
          <BiSolidStar />
          <BiStar />
        </div>

        <div className="price-box">
          <p className="price">{productObj.price}</p>
          <del>{productObj.price}</del>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
