import "./CartOrderShort.css"
import { LiaTimesSolid } from "react-icons/lia";

const CartOrderShort = () => {
  return (
    <div className="cart-order-short">
      <div className="remove">
        <LiaTimesSolid />
      </div>
      <div className="thumbail">
        <img src="/assets/imgs/products/dolphin.png" alt="dolphin" srcset="" />
      </div>
      <div className="details">
        <div className="details-title"></div>
        <div className="details-description"></div>
      </div>
      <div className="qty"></div>
      <div className="price">80.79$</div>
    </div>
  );
};

export default CartOrderShort;
