import "./CartDrawer.css";
import Button from "../common/Button";
import { LiaTimesSolid } from "react-icons/lia";
import CartOrderShort from "../common/CartOrderShort";

const CartDrawer = ({ isOpen, setFn }) => {
  return (
    <div className={`card-drawer${isOpen ? " active" : " close"}`}>
      <div className="card-overlay" onClick={() => setFn(false)}></div>
      <div
        className="card-content"
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      >
        <div className="cart-content-container">
          <div className="card-content-header">
            <h2>Your cart</h2>
            <LiaTimesSolid
              style={{ cursor: "pointer", fontSize: "1.4rem" }}
              onClick={() => setFn(false)}
            />
          </div>
          <div className="card-content-body">
            <CartOrderShort />
            <CartOrderShort />
            <CartOrderShort />
            <CartOrderShort />
          </div>
          <div className="card-content-footer">
            <div className="card-footer-detail subtotal">
              <span className="card-detail-title">SubTotal</span>
              <span className="card-detail-content">80.59$</span>
            </div>
            <div className="card-footer-detail shipping">
              <span className="card-detail-title">Shipping</span>
              <span className="card-detail-content">7.00$</span>
            </div>
            <div className="card-footer-detail total">
              <span className="card-detail-title">Total</span>
              <span className="card-detail-content">87.59$</span>
            </div>
            <div className="card-footer-cta">
              <Button text="Add to Cart" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;
