import ProductCard from "../common/ProductCard";
import { productsData } from "../../data/data";

const NewProducts = () => {
  const data = productsData.slice(0, 8);

  return (
    <div className="product-main">
      <h2 className="title">New Products</h2>
      <div className="product-grid">
        {data.map((el) => {
          return <ProductCard productObj={el} key={el.id} />;
        })}
      </div>
    </div>
  );
};

export default NewProducts;
