const productsData = [
  {
    id: 1,
    title: "Decorative Wooden Globe",
    description:
      "A stunning decorative wooden globe made from Thuya wood, perfect for enhancing any home or office space.",
    category: "home decor",
    price: 45.99,
    discountPercentage: 15,
    rating: 4.8,
    stock: 3,
    tags: ["wood decor", "globe", "handcrafted"],
    weight: 1.5,
    dimensions: {
      width: 12.0,
      height: 16.0,
      depth: 12.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890123",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-globe.jpg",
    images: [
      "/assets/imgs/products/football with support.jpg",
      "/assets/imgs/products/football with support.jpg",
    ],
  },
  {
    id: 2,
    title: "Thuya Wood Chess Set",
    description:
      "A beautifully hand-carved chess set featuring pieces made from Thuya wood. Ideal for game enthusiasts and collectors.",
    category: "games",
    price: 79.99,
    discountPercentage: 10,
    rating: 4.9,
    stock: 5,
    tags: ["chess", "games", "wood"],
    weight: 3.5,
    dimensions: {
      width: 30.0,
      height: 5.0,
      depth: 30.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890124",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-chess.jpg",
    images: [
      "/assets/imgs/products/chess roba3iya.png",
      "/assets/imgs/products/chess roba3iya 2.png",
    ],
  },
  {
    id: 3,
    title: "Thuya Wood Tissue Box",
    description:
      "An elegant tissue box made from finely carved Thuya wood, adding a luxurious touch to any room.",
    category: "home decor",
    price: 29.99,
    discountPercentage: 5,
    rating: 4.7,
    stock: 8,
    tags: ["home decor", "tissue box", "wood"],
    weight: 2.0,
    dimensions: {
      width: 25.0,
      height: 10.0,
      depth: 10.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890125",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/kniniks mkherma.png",
    images: [
      "/assets/imgs/products/kniniks mkherma.png",
      "/assets/imgs/products/kniniks mkherma.png",
    ],
  },
  {
    id: 4,
    title: "Thuya Wood Jewelry/Magic Box",
    description:
      "A handcrafted Thuya wood jewelry or magic box with a secret lock mechanism, perfect for safekeeping small treasures.",
    category: "jewelry boxes",
    price: 49.99,
    discountPercentage: 7,
    rating: 4.85,
    stock: 7,
    tags: ["jewelry box", "magic box", "wood"],
    weight: 1.2,
    dimensions: {
      width: 15.0,
      height: 8.0,
      depth: 10.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890126",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-magic-box.jpg",
    images: [
      "/assets/imgs/products/boit magic b sarout.png",
      "/assets/imgs/products/boit magic b sarout.png",
    ],
  },
  {
    id: 5,
    title: "Thuya Wood Apple-Shaped Decor",
    description:
      "A fruit-shaped wooden decor piece in the shape of an apple, carved from Thuya wood. Ideal for home or office decoration.",
    category: "home decor",
    price: 24.99,
    discountPercentage: 5,
    rating: 4.6,
    stock: 6,
    tags: ["home decor", "apple", "wood"],
    weight: 1.0,
    dimensions: {
      width: 10.0,
      height: 12.0,
      depth: 10.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890127",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-apple-decor.jpg",
    images: [
      "/assets/imgs/products/apple decor close.png",
      "/assets/imgs/products/apple decor close.png",
    ],
  },
  {
    id: 6,
    title: "Octagonal Thuya Wood Box",
    description:
      "An intricately designed octagonal box made from Thuya wood, perfect for storing jewelry or small trinkets.",
    category: "jewelry boxes",
    price: 39.99,
    discountPercentage: 10,
    rating: 4.9,
    stock: 4,
    tags: ["jewelry box", "wood", "storage"],
    weight: 1.3,
    dimensions: {
      width: 18.0,
      height: 10.0,
      depth: 18.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890128",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-octagonal-box.jpg",
    images: [
      "/assets/imgs/products/sedo9a temaniya mkherma.png",
      "/assets/imgs/products/sedo9a temaniya mkherma.png",
    ],
  },
  {
    id: 7,
    title: "Thuya Wood Letter Holder",
    description:
      "A stylish and functional letter holder made from Thuya wood, perfect for keeping your desk organized.",
    category: "desk accessories",
    price: 34.99,
    discountPercentage: 5,
    rating: 4.7,
    stock: 10,
    tags: ["desk accessories", "letter holder", "wood"],
    weight: 0.8,
    dimensions: {
      width: 20.0,
      height: 10.0,
      depth: 8.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890129",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-letter-holder.jpg",
    images: [
      "/assets/imgs/products/classeur.png",
      "/assets/imgs/products/classeur.png",
    ],
  },
  {
    id: 8,
    title: "Thuya Wood Dolphin Figurine",
    description:
      "A hand-carved dolphin figurine made from Thuya wood, symbolizing freedom and joy. Ideal as a gift or home decor.",
    category: "home decor",
    price: 19.99,
    discountPercentage: 20,
    rating: 4.85,
    stock: 12,
    tags: ["home decor", "dolphin", "figurine", "wood"],
    weight: 0.9,
    dimensions: {
      width: 15.0,
      height: 8.0,
      depth: 5.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890130",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-dolphin.jpg",
    images: [
      "/assets/imgs/products/dolphin.png",
      "/assets/imgs/products/dolphin.png",
    ],
  },

  {
    id: 9,
    title: "Essence Mascara Lash Princess",
    description:
      "The Essence Mascara Lash Princess is a popular mascara known for its volumizing and lengthening effects. Achieve dramatic lashes with this long-lasting and cruelty-free formula.",
    category: "beauty",
    price: 159.0,
    discountPercentage: 7.17,
    rating: 4.94,
    stock: 5,
    tags: ["beauty", "mascara"],
    weight: 2,
    dimensions: {
      width: 23.17,
      height: 14.43,
      depth: 28.01,
    },
    meta: {
      createdAt: "2024-05-23T08:56:21.618Z",
      updatedAt: "2024-05-23T08:56:21.618Z",
      barcode: "9164035109868",
      qrCode: "...",
    },
    thumbnail: "...",
    images: [
      "/assets/imgs/products/sendo9a.png",
      "/assets/imgs/products/sendo9a.png",
      "...",
    ],
  },
];

const homepage = {
  header: "Discover the Elegance of Thuya Wood",
  subHeader: "Crafting Timeless Beauty with Nature's Finest Materials",
  mainText:
    "Welcome to our Thuya wood store, where tradition meets modern craftsmanship. Our curated collection showcases the finest in handcrafted wooden decor, furniture, and collectibles. Each piece is a testament to the artistry and sustainability of Thuya wood, renowned for its distinctive grain and luxurious finish. Whether you're enhancing your home or searching for the perfect gift, we invite you to explore the natural elegance of our wood creations.",
  highlightedProducts: "Featured Collections for Every Occasion",
  footerText: "Crafted with care. Inspired by nature. Made for you.",
};
const categories = [
  {
    id: 1,
    name: "Home Decor",
    description:
      "Elegant Thuya wood pieces that add warmth and charm to any living space, from wall art to decorative bowls.",
    examples: [
      {
        name: "Thuya Wood Wall Art",
        description:
          "Handcrafted wall art featuring intricate designs carved from Thuya wood.",
        price: "$120",
      },
      {
        name: "Decorative Thuya Wood Bowl",
        description:
          "A beautifully turned bowl perfect for displaying fruits or as a centerpiece.",
        price: "$80",
      },
    ],
  },
  {
    id: 2,
    name: "Luxury Gifts",
    description:
      "Unique handcrafted Thuya wood gifts, perfect for special occasions and celebrations, offering an unforgettable touch.",
    examples: [
      {
        name: "Engraved Thuya Wood Pen Set",
        description:
          "A premium pen set with personalized engraving on Thuya wood, ideal for executives and professionals.",
        price: "$150",
      },
      {
        name: "Thuya Wood Jewelry Box",
        description:
          "An elegant jewelry box with velvet lining, crafted from fine Thuya wood.",
        price: "$200",
      },
    ],
  },
  {
    id: 3,
    name: "Artisan Furniture",
    description:
      "Bespoke furniture made from Thuya wood, blending traditional craftsmanship with modern design.",
    examples: [
      {
        name: "Thuya Wood Coffee Table",
        description:
          "A stylish coffee table with a polished Thuya wood surface and unique grain patterns.",
        price: "$600",
      },
      {
        name: "Custom Thuya Wood Chair",
        description:
          "A handcrafted chair combining comfort and sophistication, featuring Thuya wood accents.",
        price: "$450",
      },
    ],
  },
  {
    id: 4,
    name: "Collectibles & Curiosities",
    description:
      "Exclusive limited-edition collectibles and intricately designed Thuya wood curiosities for discerning collectors.",
    examples: [
      {
        name: "Limited Edition Thuya Wood Sculpture",
        description:
          "A one-of-a-kind sculpture carved from Thuya wood, celebrating unique artistry.",
        price: "$800",
      },
      {
        name: "Thuya Wood Inlay Puzzle Box",
        description:
          "A beautifully designed puzzle box with intricate Thuya wood inlays and hidden compartments.",
        price: "$175",
      },
    ],
  },
  {
    id: 5,
    name: "Jewelry & Keepsake Boxes",
    description:
      "Stunning handcrafted boxes designed for storing jewelry and treasured keepsakes, showcasing Thuya wood's natural beauty.",
    examples: [
      {
        name: "Thuya Wood Ring Holder",
        description:
          "A compact and elegant ring holder made from Thuya wood, perfect for organizing jewelry.",
        price: "$60",
      },
      {
        name: "Engraved Keepsake Box",
        description:
          "A personalized Thuya wood keepsake box with custom engraving for special memories.",
        price: "$90",
      },
    ],
  },
  {
    id: 6,
    name: "Eco-Friendly Essentials",
    description:
      "Sustainably sourced, eco-friendly Thuya wood products for everyday use, balancing beauty with function.",
    examples: [
      {
        name: "Thuya Wood Desk Organizer",
        description:
          "A practical and eco-friendly desk organizer made from Thuya wood, designed to declutter your workspace.",
        price: "$70",
      },
      {
        name: "Reusable Thuya Wood Cutlery Set",
        description:
          "An eco-conscious cutlery set crafted from Thuya wood, perfect for sustainable dining.",
        price: "$50",
      },
    ],
  },
];

const wood_decors = [
  {
    id: 1,
    name: "desk accessories",
    products: ["Pen Holders", "Desk Organizers", "Letter Openers", "Clocks"],
  },
  {
    id: 2,
    name: "souvenirs",
    products: [
      "Miniature Statues",
      "Carvings",
      "Wooden Keychains",
      "Decorative Plates",
    ],
  },
  {
    id: 3,
    name: "furniture",
    products: ["Tables", "Chairs", "Shelves", "Stools"],
  },
  {
    id: 4,
    name: "games",
    products: ["Chess", "Backgammon", "Checkers", "Dominoes"],
  },
  {
    id: 5,
    name: "boxes",
    products: [
      "Jewelry Boxes",
      "Keepsake Boxes",
      "Treasure Boxes",
      "Magic Boxes",
    ],
  },
  {
    id: 6,
    name: "decorative items",
    products: ["Vases", "Wall Art", "Candle Holders", "Picture Frames"],
  },
];

const motivationalPhrases = [
  "Limited Time Offer!",
  "Exclusive Deals for You!",
  "Low Stock – Order Now!",
  "Buy More, Save More!",
  "Free Shipping on All Orders!",
  "Satisfaction Guaranteed!",
  "Shop Now, Pay Later!",
  "Hurry! Offer Ends Soon!",
  "Limited Edition – Get Yours Before It's Gone!",
  "Perfect Gift for Loved Ones!",
];

export { categories, wood_decors, homepage, productsData };
