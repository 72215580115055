import "./tailwind-output.css";
import "./app.css";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

// import Select from "./components/common/Select";
// import { Button } from "@rewind-ui/core";
// import Main from "./components/layout/Main";
import ModelSubscribe from "./components/containers/ModelSubscribe";
// import Main from "./components/layout/Main";
import ProductPage from "./pages/ProductPage";

function App() {
  return (
    <>
      <ModelSubscribe />
      <Header />

      {/* <Main /> */}

      <ProductPage />
      <Footer />
    </>
  );
}

export default App;
